<template>
  <router-link to="/" class="img img--icon">
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
    >
      <path d="M8.55957 5.41C8.4148 6.0532 8.11886 6.65265 7.69628 7.1587C7.2737 7.66475 6.73664 8.06284 6.12957 8.32H5.84957C5.7703 8.23111 5.71135 8.12603 5.67681 8.01205C5.64227 7.89807 5.63298 7.77793 5.64957 7.66V0.88C5.63444 0.766688 5.64261 0.651481 5.67356 0.541434C5.70451 0.431387 5.7576 0.328815 5.82957 0.24H6.12957C6.73808 0.500469 7.27585 0.902056 7.6984 1.41155C8.12095 1.92104 8.41614 2.52381 8.55957 3.17H8.63957L9.35957 2C8.78814 1.25119 8.0194 0.676366 7.13957 0.34C6.72241 0.141694 6.27072 0.0262261 5.80957 0L5.28957 0C4.37092 0.00295899 3.47634 0.294034 2.73184 0.832226C1.98734 1.37042 1.43044 2.12861 1.13957 3C1.02439 3.41978 0.977122 3.85528 0.999569 4.29C0.982376 4.70807 1.0296 5.12629 1.13957 5.53C1.42912 6.40221 1.98569 7.16125 2.73048 7.69965C3.47527 8.23806 4.37055 8.52855 5.28957 8.53C5.45275 8.54001 5.61639 8.54001 5.77957 8.53C6.23942 8.51031 6.69109 8.40164 7.10957 8.21C7.99074 7.89049 8.76847 7.33743 9.35957 6.61L8.63957 5.39L8.55957 5.41ZM1.99957 4.39C1.99957 4.39 1.99957 4.32 1.99957 4.29C1.99957 4.26 1.99957 4.23 1.99957 4.19C1.98426 3.3666 2.23338 2.55999 2.71035 1.88863C3.18733 1.21727 3.867 0.716547 4.64957 0.46C4.68599 0.596936 4.70282 0.738341 4.69957 0.88V7.68C4.70666 7.8291 4.68632 7.97824 4.63957 8.12C3.85887 7.86171 3.18138 7.36025 2.70632 6.68904C2.23126 6.01784 1.98358 5.21216 1.99957 4.39V4.39Z" fill="currentColor" />
      <path d="M9.74938 3.8999C9.65216 3.91693 9.56256 3.96351 9.49277 4.03329C9.42298 4.10308 9.3764 4.19269 9.35938 4.2899C9.3764 4.38712 9.42298 4.47672 9.49277 4.54651C9.56256 4.6163 9.65216 4.66288 9.74938 4.6799C9.84659 4.66288 9.93619 4.6163 10.006 4.54651C10.0758 4.47672 10.1223 4.38712 10.1394 4.2899C10.1223 4.19269 10.0758 4.10308 10.006 4.03329C9.93619 3.96351 9.84659 3.91693 9.74938 3.8999V3.8999Z" fill="currentColor" />
    </svg>
  </router-link>
</template>

<style lang="scss" scoped>

a{
  width: 100%;
}

.img--icon{
    width: 100%;
    max-width: $s-mob--big;
    fill: $c-black;
    height: 100%;
    display: block;
    cursor: pointer;

    @include tb{
      max-width: $s-tab--big;
      position: relative;
      left: -$s-tab--big;
    }

    @include lg{
      max-width: $s-desk--medium;
      left: -$s-desk--medium;
    }

    @include xl{
      max-width: $s-desk--medium-avg;
    }

    svg{
      display: block;
      width: 100%;
      height: 100%;
    }

    path{
        transition: $t-smooth;
        stroke-width: 0.1px;
        fill: $c-black;
    }

    &:hover{
        transform: scale(1.1);
        transition: $t-fast;

    path{
        transition: $t-fast;
        fill: rgba($color: #000000, $alpha: 0);
        stroke: $c-black;
        }
    }

    &-dark{
      fill: $c-white;

      path{
        fill: $c-white;
      }

      &:hover{
        path{
          stroke: $c-white;
        }
      }
    }

}

</style>
